const OrderEventType = Object.freeze({
    CREATED: 0,
    DEVICE_ASSIGNED: 1,
    DEVICE_REMOVED: 2,
    DEVICE_REASSIGNED: 3,
    LABEL_PRINTED: 4,
    STATUS_PACKING: 5,
    STATUS_QA: 6,
    STATUS_PASSED_QA: 7,
    STATUS_DISPATCHED: 8,
    CANCELLED: 9,
    ON_HOLD: 10,
    OFF_HOLD: 11,
    NOTE_ADDED: 12,
    ARCHIVED: 13,
    VALIDATED: 14,
    OPEN: 15,
    ADDRESS_CHANGE: 16
})

export default OrderEventType
