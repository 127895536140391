<template>
    <div class="row">
        <div class="col">
            <div class="card text-sm pb-5">
                <div class="card-body">
                    <h5 class="mb-0">Test results</h5>

                    <div v-if="!Object.keys(results).length && test.status != 1" class="alert alert-warning mt-3">
                        Test results are not available for this test
                    </div>

                    <div v-if="Object.keys(results).length && test.notificationDate != null" class="alert alert-warning mt-3">
                        These results exceed your notification thresholds
                    </div>

                    <div>
                        <div class="row mt-4">
                            <div class="col-lg-6 pr-lg-6">
                                <div class="row">
                                    <div class="col-sm-3">Test no</div>
                                    <div class="col-sm-9">{{ test.testNumber }}</div>
                                </div>

                                <hr>

                                <div class="row">
                                    <div class="col-sm-3">Facility name</div>
                                    <div class="col-sm-9">{{ test.facilityName }}</div>
                                </div>

                                <hr>

                                <div class="row">
                                    <div class="col-sm-3">Organisation name</div>
                                    <div class="col-sm-9">{{ organisation.name }}</div>
                                </div>

                                <hr>

                                <div class="row">
                                    <div class="col-sm-3">Practitioner name</div>
                                    <div class="col-sm-9">{{ test.practitionerName }}</div>
                                </div>

                                <div v-if="formatAddress(test.shippingAddress) != ''">
                                    <hr>

                                    <div class="row">
                                        <div class="col-sm-3">Shipping address</div>
                                        <div class="col-sm-7">
                                            <span v-if="test.shippingAddress.line">{{ test.shippingAddress.line }}, </span>
                                            <span v-if="test.shippingAddress.line2">{{ test.shippingAddress.line2 }}, </span>
                                            <span v-if="test.shippingAddress.line3">{{ test.shippingAddress.line3 }}, </span>
                                            <span v-if="test.shippingAddress.city">{{ test.shippingAddress.city }}, </span>
                                            <span v-if="test.shippingAddress.district">{{ test.shippingAddress.district }}, </span>
                                            <span v-if="test.shippingAddress.state">{{ test.shippingAddress.state }}, </span>
                                            <span v-if="test.shippingAddress.postalCode">{{ test.shippingAddress.postalCode }}, </span>
                                            <span v-if="test.shippingAddress.country">{{ test.shippingAddress.country }}, </span>
                                            <span v-if="test.shippingAddress.countryCode">{{ test.shippingAddress.countryCode }}</span>
                                        </div>
                                        <div v-if="canChangeOrder()" class="col-sm-2">
                                            <button @click="changeOrderAddress()" class="btn btn-primary">Change</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 pl-lg-6 mt-4 mt-md-0">
                                <div class="row">
                                    <div class="col-sm-3">Testing for</div>
                                    <div class="col-sm-9">
                                        <span v-if="test.reason == 0">Gestational Diabetes Mellitus</span>
                                        <span v-if="test.reason == 1">Cystic Fibrosis</span>
                                        <span v-if="test.reason == 2">Type 1</span>
                                        <span v-if="test.reason == 3">Research study</span>
                                    </div>
                                </div>

                                <hr>

                                <div class="row">
                                    <div class="col-sm-3">Status</div>
                                    <div class="col-sm-9">
                                        <testStatuses :status="test.status" />
                                    </div>
                                </div>

                                <hr>

                                <div class="row">
                                    <div class="col-sm-3">Test created</div>
                                    <div class="col-sm-9">{{ test.dateCreated | moment("DD/MM/YYYY HH:mm") }}</div>
                                </div>
                            </div>
                        </div>

                        <div v-if="Object.keys(results).length" class="mt-4">
                            <hr class="my-4">

                            <div class="row" v-if="results.hasResults">
                                <div class="col-md-6 pr-lg-6">
                                    <h6>Fasting</h6>

                                    <div class="test-meta p-3 mt-4">
                                        <div class="row">
                                            <div class="col-sm-3">Result</div>
                                            <div class="col-sm-9">{{ calculateTestResult(results.testAResult) }} mmol/l</div>
                                        </div>

                                        <hr>

                                        <div class="row">
                                            <div class="col-sm-3">Completed</div>
                                            <div class="col-sm-9">{{ results.testATime | moment("DD/MM/YYYY HH:mm") }}</div>
                                        </div>

                                        <hr>

                                        <div class="row">
                                            <div class="col-sm-3">Raw value</div>
                                            <div class="col-sm-9">{{ (results.testAValue * 1000000000).toFixed(2) }} nA</div>
                                        </div>

                                        <hr>

                                        <div class="row">
                                            <div class="col-sm-3">Temperature</div>
                                            <div class="col-sm-9">{{ results.testATemp.toFixed(1) }}&deg;C</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 pl-lg-6 mt-4 mt-md-0">
                                    <h6>2 hour</h6>

                                    <div class="test-meta p-3 mt-4">
                                        <div class="row">
                                            <div class="col-sm-3">Result</div>
                                            <div class="col-sm-9">{{ calculateTestResult(results.testBResult) }} mmol/L</div>
                                        </div>

                                        <hr>

                                        <div class="row">
                                            <div class="col-sm-3">Completed</div>
                                            <div class="col-sm-9">{{ results.testBTime | moment("DD/MM/YYYY HH:mm") }}</div>
                                        </div>

                                        <hr>

                                        <div class="row">
                                            <div class="col-sm-3">Raw value</div>
                                            <div class="col-sm-9">{{ (results.testBValue * 1000000000).toFixed(2) }} nA</div>
                                        </div>

                                        <hr>

                                        <div class="row">
                                            <div class="col-sm-3">Temperature</div>
                                            <div class="col-sm-9">{{ results.testBTemp.toFixed(1) }}&deg;C</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-md-6 pr-lg-6">
                                    <h6>Device details</h6>

                                    <div class="test-meta p-3 mt-4">
                                        <div class="row">
                                            <div class="col-sm-3">Device no</div>
                                            <div class="col-sm-9">{{ results.serialNumber }}</div>
                                        </div>

                                        <div v-if="results.details.softwareVersion">
                                            <hr>

                                            <div class="row">
                                                <div class="col-sm-3">Software version</div>
                                                <div class="col-sm-9">{{ results.details.softwareVersion }}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="results.hasErrors && results.validationErrors.length" class="mt-4">
                                        <h6>Validation Errors</h6>
                                        <b-list-group class="mt-4">
                                            <b-list-group-item v-for="(item, index) in results.validationErrors" :key="index" class="text-danger font-weight-bold">{{ `${item}: ${ValidationErrors[item]}` }}</b-list-group-item>
                                        </b-list-group>
                                    </div>

                                    <div v-if="results.hasErrors && results.errorFlags.length" class="mt-4">
                                        <h6>Error Flags</h6>
                                        <b-list-group class="mt-4">
                                            <b-list-group-item v-for="(item, index) in results.errorFlags" :key="index" class="text-danger font-weight-bold">{{ `${item}: ${ErrorFlags[item]}` }}</b-list-group-item>
                                        </b-list-group>
                                    </div>
                                </div>

                                <div class="col-md-6 pl-lg-6 mt-4 mt-md-0">
                                    <h6>Raw Data</h6>

                                    <button @click="openRawDataModal()" class="btn btn-secondary sm-shadow mt-4">Raw Data</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TestStatuses from '@/components/TestStatusesComponent.vue'
import RawDataModal from '@/components/modals/RawDataModalComponent.vue'
import ValidationErrors from '@/errors/ValidationErrors'
import ErrorFlags from '@/errors/ErrorFlags'
import FormatterMixin from '@/mixins/FormatterMixin'
import ChangeOrderAddressModal from '@/components/modals/ChangeOrderAddressModalComponent'
import TestStatus from '@/enums/TestStatus'
export default {
    name: 'TestResultsComponent',

    mixins: [
        FormatterMixin
    ],

    components: {
        TestStatuses
    },

    props: {
        test: Object,
        organisation: Object,
        results: Object,
        patient: Object
    },

    data () {
        return {
            ValidationErrors,
            ErrorFlags,
            RawDataModal,
            ChangeOrderAddressModal,
            TestStatus
        }
    },

    methods: {
        calculateTestResult (val) {
            const result = (val * 1000).toFixed(2)
            return result
        },

        openRawDataModal () {
            this.$modal.show(RawDataModal, { data: this.results })
        },

        changeOrderAddress () {
            this.$modal.show(ChangeOrderAddressModal, { organisationId: this.test.organisationId, patient: this.patient, orderId: this.test.orderId })
        },

        canChangeOrder () {
            return this.test.status < this.TestStatus.RETURNED
        }
    }
}
</script>
