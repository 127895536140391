const Ethnicity = Object.freeze({
    BANGLADESHI: 0,
    BLACK_AFRICAN: 1,
    BLACK_CARIBBEAN: 2,
    CHINESE: 3,
    INDIAN: 4,
    MIDDLE_EASTERN: 5,
    MIXED: 6,
    PAKISTANI: 7,
    WHITE: 8,
    OTHER: 9,
    PREFER_NOT_TO_SAY: 10
})

export default Ethnicity
