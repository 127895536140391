<template>
    <div class="row">
        <div class="col">
            <div class="card text-sm">
                <div class="card-body">
                    <div class="d-flex flex-column flex-md-row justify-content-between">
                        <h5 class="mb-0">Patient Details</h5>
                    </div>

                    <div class="row mt-3">
                        <div class="col-lg-6 pr-lg-6">
                            <div class="row">
                                <div class="col-sm-3 font-weight-medium">Name</div>
                                <div class="col-sm-9 text-capitalize">{{ `${patient.firstName} ${patient.middleNames ? patient.middleNames : ''} ${patient.surname}` }}</div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-sm-3 font-weight-medium">DOB</div>
                                <div class="col-sm-9">{{ patient.dateOfBirth | moment("DD/MM/YYYY") }}</div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-sm-3 font-weight-medium">{{ organisation.patientIdentifierLabel }}</div>
                                <div class="col-sm-9">{{ patient.identifier }}</div>
                            </div>

                            <div v-if="gdEstimatedDeliveryDate != null">
                                <hr>

                                <div class="row">
                                    <div class="col-sm-3 font-weight-medium">Baby estimated delivery date (EDD)</div>
                                    <div class="col-sm-9">
                                        {{ formatDate(gdEstimatedDeliveryDate) }} <span v-if="formatGA(gdEstimatedDeliveryDate) != null">| Gestational age in weeks = {{ formatGA(gdEstimatedDeliveryDate) }}</span>
                                    </div>
                                </div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-sm-3 font-weight-medium">Phone no</div>
                                <div class="col-sm-9">{{ patient.phone }}</div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-sm-3 font-weight-medium">Email</div>
                                <div class="col-sm-9">{{ patient.email }}</div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-sm-3 font-weight-medium">Address</div>
                                <div class="col-sm-9">
                                    {{ formatAddress(patient) }}
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 pl-lg-6 mt-5 mt-lg-0">

                            <div class="row">
                                <div class="col-sm-3 font-weight-medium">Height</div>
                                <div class="col-sm-9">{{ convertCentimetresToFeetAndInches(patient.height) }} | {{ patient.height }}cm</div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-sm-3 font-weight-medium">Weight</div>
                                <div class="col-sm-9">{{ convertKilosToStonesAndPounds(patient.weight) }} | {{ patient.weight }}kg </div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-sm-3 font-weight-medium">Gender</div>
                                <div v-if="patient.gender == Gender.MALE" class="col-md-9">Male</div>
                                <div v-if="patient.gender == Gender.FEMALE" class="col-md-9">Female</div>
                                <div v-if="patient.gender == Gender.OTHER" class="col-md-9">Other</div>
                                <div v-if="patient.gender == Gender.UNKNOWN" class="col-md-9">Unknown</div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-sm-3 font-weight-medium">Ethnicity group</div>
                                <div v-if="patient.ethnicity == Ethnicity.BANGLADESHI" class="col-md-9">Bangladeshi</div>
                                <div v-if="patient.ethnicity == Ethnicity.BLACK_AFRICAN" class="col-md-9">Black african</div>
                                <div v-if="patient.ethnicity == Ethnicity.BLACK_CARIBBEAN" class="col-md-9">Black caribbean</div>
                                <div v-if="patient.ethnicity == Ethnicity.CHINESE" class="col-md-9">Chinese</div>
                                <div v-if="patient.ethnicity == Ethnicity.INDIAN" class="col-md-9">Indian</div>
                                <div v-if="patient.ethnicity == Ethnicity.MIDDLE_EASTERN" class="col-md-9">Middle eastern</div>
                                <div v-if="patient.ethnicity == Ethnicity.MIXED" class="col-md-9">Mixed</div>
                                <div v-if="patient.ethnicity == Ethnicity.PAKISTANI" class="col-md-9">Pakistani</div>
                                <div v-if="patient.ethnicity == Ethnicity.WHITE" class="col-md-9">White</div>
                                <div v-if="patient.ethnicity == Ethnicity.OTHER" class="col-md-9">Other</div>
                                <div v-if="patient.ethnicity == Ethnicity.PREFER_NOT_TO_SAY" class="col-md-9">Prefer not to say</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import WeightConversionMixin from '@/mixins/WeightConversionMixin'
import FormatterMixin from '@/mixins/FormatterMixin'
import Gender from '@/enums/Gender'
import Ethnicity from '@/enums/Ethnicity'
export default {
    name: 'PatientDetailsComponent',

    props: {
        patient: Object,
        organisation: Object,
        gdEstimatedDeliveryDate: String,
        hideEdit: Boolean(false)
    },

    mixins: [
        WeightConversionMixin,
        FormatterMixin
    ],

    data () {
        return {
            Gender,
            Ethnicity
        }
    }
}
</script>
