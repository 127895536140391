<template>
    <div>
        <p class="modal-heading">Change shipping address?</p>
        <div v-if="!expandManualAddress" class="mt-4">
            <label for="autocomplete">Find Address</label>
            <input :disabled="isBusy" type="text" class="form-control" @input="findAddress()" v-model="addressQueryString" placeholder="Search for address" autocomplete="new-password" />
            <ul v-if="autocompleteSuggestions.length" class="list-group autocomplete-suggestions">
                <li v-for="(suggestion, index) in autocompleteSuggestions" :key="index" class="list-group-item" @click="chooseSuggestion(suggestion.id)">{{ suggestion.address }}</li>
            </ul>
            <button class="btn show-manual-address mt-2" @click="enterAddressManually()">Enter address manually</button>
        </div>

        <div v-if="expandManualAddress" class="mt-4">
            <div class="form-group row">
                <label for="address1" class="col-sm-4 col-form-label">Address 1 <span class="text-danger">*</span></label>
                <div class="col-sm-8">
                    <input :disabled="isBusy" type="text" class="form-control" :class="{'border border-danger' : errors && errors.address1}" id="address1" v-model="form.address1" autocomplete="new-password">
                    <small v-if="errors && errors.address1" class="text-danger">
                        {{ errors.address1[0] }}
                    </small>
                </div>
            </div>

            <div class="form-group row">
                <label for="address2" class="col-sm-4 col-form-label">Address 2</label>
                <div class="col-sm-8">
                    <input :disabled="isBusy" type="text" class="form-control" :class="{'border border-danger' : errors && errors.address2}" id="address2" v-model="form.address2" autocomplete="new-password">
                    <small v-if="errors && errors.address2" class="text-danger">
                        {{ errors.address2[0] }}
                    </small>
                </div>
            </div>

            <div class="form-group row">
                <label for="address3" class="col-sm-4 col-form-label">Address 3</label>
                <div class="col-sm-8">
                    <input :disabled="isBusy" type="text" class="form-control" :class="{'border border-danger' : errors && errors.address3}" id="address3" v-model="form.address3" autocomplete="new-password">
                    <small v-if="errors && errors.address3" class="text-danger">
                        {{ errors.address3[0] }}
                    </small>
                </div>
            </div>

            <div class="form-group row">
                <label for="address3" class="col-sm-4 col-form-label">City <span class="text-danger">*</span></label>
                <div class="col-sm-8">
                    <input :disabled="isBusy" type="text" class="form-control" :class="{'border border-danger' : errors && errors.city}" id="city" v-model="form.city" autocomplete="new-password">
                    <small v-if="errors && errors.city" class="text-danger">
                        {{ errors.city[0] }}
                    </small>
                </div>
            </div>

            <div class="form-group row">
                <label for="address3" class="col-sm-4 col-form-label">County</label>
                <div class="col-sm-8">
                    <input :disabled="isBusy" type="text" class="form-control" :class="{'border border-danger' : errors && errors.district}" id="district" v-model="form.district" autocomplete="new-password">
                    <small v-if="errors && errors.district" class="text-danger">
                        {{ errors.district[0] }}
                    </small>
                </div>
            </div>

            <div class="form-group row">
                <label for="postalCode" class="col-sm-4 col-form-label">Postcode <span class="text-danger">*</span></label>
                <div class="col-sm-8">
                    <input :disabled="isBusy" type="text" class="form-control" :class="{'border border-danger' : errors && errors.postalCode}" id="postalCode" v-model="form.postalCode" autocomplete="new-password">
                    <small v-if="errors && errors.postalCode" class="text-danger">
                        {{ errors.postalCode[0] }}
                    </small>
                </div>
            </div>

            <div class="form-group row">
                <label for="country" class="col-sm-4 col-form-label">Country <span class="text-danger">*</span></label>
                <div class="col-sm-8">
                    <input readonly :disabled="isBusy" type="text" class="form-control" id="country" v-model="form.country" autocomplete="new-password">
                    <small v-if="errors && errors.country" class="text-danger">
                        {{ errors.country[0] }}
                    </small>
                </div>
            </div>

            <div>
                <button class="btn show-manual-address mt-2" @click="enterAddressByLookup()">Back to find address</button>
            </div>

            <div class="text-right mt-4">
                <div>
                    <b-form-checkbox v-model="form.changePatientRecordAndFutureOrders" name="check-button">
                        Apply to patient record and future orders
                    </b-form-checkbox>
                </div>
                <button @click="save()" :disabled="loading || !canSave()" class="btn btn-success btn-lg lg-shadow mt-3" :class="{disabled : loading || !canSave()}">Save <span v-if="loading" class="btn-loading"></span></button>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapActions } from 'vuex'
    import axios from 'axios'
    export default {
        name: 'ChangeOrderAddressModalComponent',

        props: {
            organisationId: String,
            orderId: String,
            patient: Object
        },

        data () {
            return {
                loading: false,
                isBusy: false,
                fulladdress: null,
                expandManualAddress: false,
                addressQueryString: null,
                autocompleteSuggestions: [],
                form: {
                    address1: null,
                    address2: null,
                    address3: null,
                    address4: null,
                    city: null,
                    district: null,
                    postalCode: null,
                    country: null,
                    countryCode: null,
                    longitude: 0,
                    latitude: 0,
                    changePatientRecordAndFutureOrders: false
                },
                errors: false
            }
        },

        methods: {
            ...mapActions({
                setNotification: 'NotificationStore/SetNotification',
                setShouldRefreshViewTestComponent: 'AppStore/SetShouldRefreshViewTestComponent'
            }),

            async findAddress () {
                const response = await axios.get(`https://api.getAddress.io/autocomplete/${this.addressQueryString}?api-key=${process.env.VUE_APP_GET_ADDRESS_API_KEY}`)
                this.autocompleteSuggestions = response.data.suggestions
            },

            async chooseSuggestion (id) {
                this.clearAddress()

                const response = await axios.get(`https://api.getAddress.io/get/${id}?api-key=${process.env.VUE_APP_GET_ADDRESS_API_KEY}`)
                if (response.data) {
                    this.autocompleteSuggestions = []
                    this.addressQueryString = null
                    this.expandManualAddress = true
                    this.form.address1 = response.data.line_1
                    this.form.address2 = response.data.line_2
                    this.form.address3 = response.data.line_3
                    this.form.city = response.data.town_or_city
                    this.form.district = response.data.county
                    this.form.postalCode = response.data.postcode
                    this.form.country = response.data.country
                    this.form.countryCode = 'GB'
                    this.form.latitude = response.data.latitude
                    this.form.longitude = response.data.longitude
                }
            },

            clearAddress () {
                this.fullAddress = null
                this.form.address1 = null
                this.form.address2 = null
                this.form.address3 = null
                this.form.address4 = null
                this.form.city = null
                this.form.district = null
                this.form.postalCode = null
                this.form.country = null
                this.form.latitude = null
                this.form.longitude = null
                this.form.changePatientRecordAndFutureOrders = false
            },

            enterAddressManually () {
                this.expandManualAddress = true
            },

            enterAddressByLookup () {
                this.clearAddress()
                this.expandManualAddress = false
            },

            canSave () {
                 return this.form.address1 != null &&
                        this.form.address1 !== '' &&
                        this.form.city != null &&
                        this.form.city !== '' &&
                        this.form.postalCode != null &&
                        this.form.postalCode !== '' &&
                        this.form.country != null &&
                        this.form.country !== ''
            },

            async save () {
                this.loading = true
                try {
                    await axios.patch(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/orders/updateShippingAddress?orderId=${this.orderId}&patientId=${this.patient.id}&organisationId=${this.organisationId}`, this.form)

                    if (this.form.changePatientRecordAndFutureOrders) {
                        var patientModel = JSON.parse(JSON.stringify(this.patient))
                        patientModel.address1 = this.form.address1
                        patientModel.address2 = this.form.address2
                        patientModel.address3 = this.form.address3
                        patientModel.address4 = this.form.address4
                        patientModel.city = this.form.city
                        patientModel.district = this.form.district
                        patientModel.postalCode = this.form.postalCode
                        patientModel.country = this.form.country
                        patientModel.countryCode = this.form.countryCode
                        patientModel.longitude = this.form.longitude
                        patientModel.latitude = this.form.latitude

                        await axios.patch(`${process.env.VUE_APP_AXIOS_PATIENT_BASE_URL}/api/v1/patients/${this.organisationId}/${this.patient.id}`, patientModel)
                    }

                    this.setNotification({
                        type: 'success',
                        title: 'Success',
                        message: 'The shipping address was updated.'
                    })
                    this.setShouldRefreshViewTestComponent(true)
                } catch {
                    this.$emit('close')
                    this.setNotification({
                        type: 'error',
                        title: 'Error',
                        message: 'Sorry, something went wrong. Please try again'
                    })
                } finally {
                    this.$emit('close')
                }
            }
        }
    }
</script>
